import Head from 'next/head'
import { AppProps } from 'next/app'
import '../common/globals.css'

const ViolaPeter = ({ Component, pageProps }: AppProps) => {
  const title = 'Péter Viola'
  const description = 'Péter Viola - Frontend Developer'
  
  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=yes"/>
        <meta name="description" content={description}/>
      
        <meta name='application-name' content={title} />
        <meta name='apple-mobile-web-app-capable' content='yes' />
        <meta name='apple-mobile-web-app-status-bar-style' content='default' />
        <meta name='apple-mobile-web-app-title' content={title} />
        <meta name='description' content={description} />
        <meta name='format-detection' content='telephone=no' />
        <meta name='mobile-web-app-capable' content='yes' />
        <meta name='msapplication-config' content='/icons/browserconfig.xml' />
        <meta name='msapplication-TileColor' content='#2B5797' />
        <meta name='msapplication-tap-highlight' content='no' />
        <meta name='theme-color' content='#000000' />
      
        <link rel='apple-touch-icon' href='/icons/icon-96x96.png' />
        <link rel='apple-touch-icon' sizes='152x152' href='/icons/icon-152x152.png' />
      
        <link rel='manifest' href='/manifest.json' />
      
        <meta name='twitter:card' content='summary' />
        <meta name='twitter:url' content='https://violapeter.hu' />
        <meta name='twitter:title' content={title} />
        <meta name='twitter:description' content={description} />
        <meta name='twitter:image' content='/icons/icon-192x192.png' />
        <meta name='twitter:creator' content='@violapeter' />
        <meta property='og:type' content='website' />
        <meta property='og:title' content={title} />
        <meta property='og:description' content={description} />
        <meta property='og:site_name' content={title} />
        <meta property='og:url' content='https://violapeter.hu' />
        <meta property='og:image' content='https://yourdomain.com/icons/apple-touch-icon.png' />
      
      
        <link rel="icon" type="image/png" sizes="48x48" href="/icons/icon-48x48.png" />
      </Head>
      <Component {...pageProps} />
    </>
  )
}

export default ViolaPeter
